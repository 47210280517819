import PropTypes from 'prop-types'
import React from 'react'

import styles from './AListTextItem.module.scss'

const AListTextItem = ({ title, paragraph }) => (
  <li className={styles.item}>
    <h2 className={styles.itemHeadline}>{title}</h2>
    {paragraph}
  </li>
)

AListTextItem.propTypes = {
  title: PropTypes.node.isRequired,
  paragraph: PropTypes.node.isRequired,
}

export default AListTextItem
