import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'

import AListTextItem from '../components/01-atoms/AListTextItem'
import AType from '../components/01-atoms/AType'

import MListText from '../components/02-molecules/MListText'

import OIntro from '../components/03-organisms/OIntro'

const IndexPage = ({ data }) => (
    <Layout>
        <Helmet>
            <title>Team & Aktuelles</title>
            <meta
                name="description"
                content="Unsere Leistungen beim Augenoptiker »Optique - Meisterhandwerk und Brillenmanufaktur«: Sehtest, Sehberatung, Beratung, Kontaktlinse, Brille, Fassungsberatung, Kundenservice"
            />
        </Helmet>

        <OIntro
            title={
                <AType type="h1" className="title">
                    Team & Aktuelles
                </AType>
            }
            subtitle={
                <AType type="h2" className="subtitle">
                    Gute Stimmung und familiäre Atmosphäre werden in der Optique nicht nur großgeschrieben, sondern auch gelebt.
                </AType>
            }
        />

        <MListText
            intro={
                <div>
                    <AType className="lead" align="center">
                        So ergänzen seit einiger Zeit <strong>Heidi Weinmann</strong> (Augenoptikerin) und <strong>Simon Rützel</strong> (Augenoptikermeister) das Team die Chefin <strong>Corinna Becht</strong> (Augenoptikermeisterin).
                    </AType>
                    <AType className="lead" align="center">
                        Mit ihrem sicheren Händchen für Mode und einem Herz aus Gold sorgt Heidi für frischen Wind und organisiert ganz nebenbei noch Werkstatt und Büro.</AType>
                    <AType className="lead" align="center">
                        Simon ist unser Garant für gute Laune, ein Charmebolzen, dem sich kaum einer entziehen kann. Durch jahrelange Fachkenntnis und stetige Weiterbildungen ist er Ansprechpartner für alle Anliegen optischer Korrektionsmöglichkeiten.
                    </AType>
                    <AType className="lead" align="center">
                        Dass die Optique ihr „Baby“ ist, kann man bei Chefin Corinna spüren. „Kennt jede Brille mit Vornamen“ ist ein gerne genutzter Spruch ihrer Kollegen und tatsächlich wird mit Feingefühl geschaut, dass auch die richtigen Brillen den Weg in die Optique und vor allem auf die Nase des Kunden finden. Dass sie ihr Handwerk beherrscht, konnte sie bereits früh unter Beweis stellen: z.B. als Bundessiegerin ihres Abschlussjahrganges und auch aktuell als Mitglied der Meisterprüfungskommission.
                    </AType>
                    <AType className="lead" align="center">
                        Zu guter Letzt sorgt unser Juniorchef Rocco für den nötigen Wirbel – Nachwuchs kam im Januar 2022 zur Welt und mischt in der Optique munter mit.
                    </AType>
                    <AType className="lead" align="center">
                        Da wir weiterwachsen, suchen wir stets nach Augenoptiker/innen die zu uns passen, melde dich also gerne wenn du Lust auf die Optique und vor allem die Menschen drumherum bekommen hast!
                    </AType>
                </div>
            }
        />
    </Layout>
)
IndexPage.propTypes = {
    data: PropTypes.shape.isRequired,
}
export default IndexPage

export const query = graphql`
  query {
    co: file(
      relativePath: { eq: "shop/augenoptiker_optique__MG_2799_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900, excludeOriginal: true) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
