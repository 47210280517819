import PropTypes from 'prop-types'
import React from 'react'

import styles from './MListText.module.scss'

const MListText = ({ children, intro }) => (
  <section className={styles.container}>
    {intro}
    <ul className={styles.list}>{children}</ul>
  </section>
)

MListText.propTypes = {
  children: PropTypes.oneOf([PropTypes.node, PropTypes.boolean]),
  intro: PropTypes.oneOf([PropTypes.node, PropTypes.boolean]),
}

MListText.defaultProps = {
  children: PropTypes.node,
  intro: false,
}

export default MListText
